import React from 'react'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import TailwindLayout from '../components/Layouts/TailwindLayout'
import { GetStarted } from '@truphone/get-started-components'
import { mapSecondaryMenu } from '../utils'

function GetStartedPage({
  path,
  data: { datoCmsContactPage, datoCmsSite },
  pageContext: { menu, footer, cookiePrompt, consumer }
}) {
  return (
    <TailwindLayout
      menu={{
        ...menu,
        secondaryMenu: mapSecondaryMenu(datoCmsContactPage.secondaryMenu, path),
        dark: true
      }}
      footer={footer}
      cookiePrompt={cookiePrompt}
      consumer={consumer}
    >
      <HelmetDatoCms
        seo={datoCmsContactPage.seoMetaTags || datoCmsSite.seoMetaTags}
        favicon={datoCmsSite.faviconMetaTags}
      />
      <GetStarted
        topTitle={datoCmsContactPage.topTitle}
        title={datoCmsContactPage.title}
        subtitle={datoCmsContactPage.subtitle}
        forms={datoCmsContactPage.forms?.map((x) => ({
          id: x.fragment,
          title: x.title,
          content: x.contentNode?.childMarkdownRemark.html,
          image: x.image,
          cta: x.cta && x.cta.length > 0 && x.cta[0],
          formContainer: x.formContainer && x.formContainer.length > 0 && x.formContainer[0]
        }))}
      />
    </TailwindLayout>
  )
}

export default GetStartedPage

export const query = graphql`
  query GetStartedPage($locale: String!) {
    datoCmsSite {
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    datoCmsContactPage(locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      secondaryMenu {
        ...MenuParentItemProps
      }
      topTitle
      title
      subtitle
      forms {
        fragment
        title
        contentNode {
          childMarkdownRemark {
            html
          }
        }
        image {
          url
          alt
          gatsbyImageData
        }
        cta {
          title
          text
          href
          openTab
        }
        formContainer {
          ...FormContainerProps
        }
      }
    }
  }
`
